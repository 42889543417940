import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import Cta, { CtaProps } from "@components/elements/cta"

import CrossIcon from "@images/cross.inline.svg"

import styles from "./cards.module.scss"

interface CardsProps {
  title?: string
  text?: string
  cta?: CtaProps
  items: Array<{
    text: string
  }>
}

const Cards = ({ title, text, cta, items = [] }: CardsProps) => (
  <section className={styles.cards}>
    <Container className={styles.container} isNarrow>
      {title && <h2 className={styles.title}>{title}</h2>}
      {text && <Wysiwyg className={styles.text} contentHtml={text} />}
      <Cta {...cta} className={styles.buttonCta} />

      <div className={styles.list}>
        {items.map(
          (item, index) =>
            item.text && (
              <div className={styles.item} key={index}>
                <div className={styles.itemText}>
                  <Wysiwyg contentHtml={item.text.html} />
                  <CrossIcon className={styles.itemIcon} />
                </div>
              </div>
            )
        )}
      </div>
    </Container>

    <StaticImage
      className={styles.background}
      src="../images/bg-dots.png"
      placeholder="none"
      alt=""
    />
  </section>
)

export default Cards
