import * as React from "react"
import { Script } from "gatsby"

import Container from "@components/elements/container"

import styles from "./jobs.module.scss"

interface JobsPops {}

const FeaturedContent = ({}: JobsPops) => {
  return (
    <section className={styles.jobs}>
      <Container className={styles.container} isNarrow>
        <h2 className={styles.title}>Open roles</h2>

        <Script
          src="https://www.workable.com/assets/embed.js"
          onLoad={() => {
            whr(document).ready(function () {
              whr_embed(581911, {
                detail: "titles",
                base: "jobs",
                zoom: "country",
                grouping: "none",
              })
            })
          }}
        />

        <div id="whr_embed_hook" className={styles.jobsContent}></div>
      </Container>
    </section>
  )
}

export default FeaturedContent
