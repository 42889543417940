import * as React from "react"
import { graphql } from "gatsby"
import { useRef } from "react"

import Layout from "@components/layout/layout"
import GradientBackground from "@components/elements/gradientBackground"
import CareersHero from "@components/careersHero"
import Cards from "@components/cards"
import FeaturedContent from "@components/featuredContent"
import Jobs from "@components/jobs"
import Footer from "@components/layout/footer"

import { scrollToElement } from "@lib/utils"

const MAX_ABBREVIATION_LENGTH = 75

const Careers = ({ data }: any) => {
  const {
    meta_title,
    meta_description,
    meta_image,
    hero_images,
    join_title,
    join_text,
    join_cta_text,
    join_cta_link,
    join_cards,
    why_title,
    why_text,
    featured_content,
  } = data.prismicCareers.data
  const whySectionRef = useRef(null)
  const rolesSectionRef = useRef(null)

  const featuredItems = featured_content.map(({ content }) => {
    const data = {
      id: content.document.id,
      contentType: content.document.type,
      imageData: null,
      hasVideo: false,
    }

    switch (data.contentType) {
      case "news_post":
        data.postType = "news"
        data.slug = content.document.uid
        data.title = content.document.data.title.text
        data.text = ""

        for (let el of content.document.data.body) {
          if (el.slice_type === "paragraph") {
            if (data.text === "") {
              data.text = el.primary.content.text.substring(
                0,
                MAX_ABBREVIATION_LENGTH
              )
            }

            if (el.primary.paragraph_image) {
              data.imageData = el.primary.paragraph_image
            }

            if (
              (el.primary.youtube_link && el.primary.youtube_link.html) ||
              (el.primary.video && el.primary.video.url)
            ) {
              data.hasVideo = true
            }
          }
        }

        break
      case "quotation":
        data.imageData = content.document.data.image
        data.text = content.document.data.quotation_text.text
        data.authorName = content.document.data.author_name
        data.authorPosition = content.document.data.author_position

        break

      default:
        console.warn(`No support for content type ${data.type}`)

        break
    }

    return data
  })

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
      headerLight
      showFooter={false}
    >
      <GradientBackground noLight>
        <CareersHero
          title="Careers"
          images={hero_images}
          cta={{
            text: "Why join Phasecraft",
            onClick: () => scrollToElement(whySectionRef.current),
          }}
        />
      </GradientBackground>
      <Cards
        title={join_title}
        text={join_text.text}
        cta={{
          text: join_cta_text,
          url: join_cta_link.url,
          onClick: () => scrollToElement(rolesSectionRef.current),
        }}
        items={join_cards}
      />
      <GradientBackground noLight>
        <div ref={whySectionRef}></div>
        <FeaturedContent
          title={why_title}
          text={why_text.html}
          items={featuredItems}
        />
        <div ref={rolesSectionRef}></div>
        <Jobs />
        <Footer
          noBackground
          cta={{
            text: "careers@phasecraft.io",
            url: "mailto:careers@phasecraft.io",
          }}
        />
      </GradientBackground>
    </Layout>
  )
}

export default Careers

export const pageQuery = graphql`
  query {
    prismicCareers {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        hero_images {
          imageData: image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        join_title
        join_text {
          text
        }
        join_cta_text
        join_cta_link {
          url
        }
        join_cards {
          text {
            html
          }
        }
        why_title
        why_text {
          html
        }
        featured_content {
          content {
            document {
              ... on PrismicNewsPost {
                id
                type
                uid
                data {
                  title {
                    text
                  }
                  body {
                    ... on PrismicNewsPostDataBodyParagraph {
                      id
                      primary {
                        content {
                          text
                        }
                        paragraph_image {
                          gatsbyImageData(
                            placeholder: BLURRED
                            aspectRatio: 2.14
                          )
                        }
                        youtube_link {
                          html
                        }
                        video {
                          url
                        }
                      }
                      slice_type
                    }
                  }
                }
              }
              ... on PrismicQuotation {
                id
                type
                data {
                  author_name
                  author_position
                  quotation_text {
                    text
                  }
                  image {
                    gatsbyImageData(placeholder: BLURRED, aspectRatio: 1)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
