import * as React from "react"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"

import styles from "./quote.module.scss"

export interface PostProps {
  text: string
  imageData: ImageDataLike
}

const Quote = ({ text, authorName, authorPosition, imageData }: PostProps) => {
  const image = getImage(imageData)

  return (
    <div className={styles.quote}>
      <GatsbyImage className={styles.picture} image={image} alt="" />

      <div className={styles.content}>
        {text && <blockquote className={styles.text}>{text}</blockquote>}
        {authorName && <div className={styles.authorName}>{authorName}</div>}
        {authorPosition && (
          <div className={styles.authorPosition}>{authorPosition}</div>
        )}
      </div>
    </div>
  )
}

export default Quote
