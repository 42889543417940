import * as React from "react"

import Container from "@components/elements/container"
import Card from "@components/elements/card"
import Quote from "@components/elements/quote"
import Wysiwyg from "@components/elements/wysiwyg"

import styles from "./featuredContent.module.scss"

interface FeaturedContentProps {
  title?: string
  items: Array<PostProps>
}

const FeaturedContent = ({ title, text, items }: FeaturedContentProps) => {
  return (
    <section className={styles.featuredContent}>
      <Container className={styles.container} isNarrow>
        {title && <h2 className={styles.title}>{title}</h2>}

        <Wysiwyg className={styles.text} contentHtml={text} />

        <div className={styles.list}>
          {items.map(item => {
            switch (item.contentType) {
              case "news_post":
                return <Card {...item} key={item.id} variant={"dark"} />
              case "quotation":
                return <Quote {...item} key={item.id} />
              default:
                console.warn(`No support for content type ${item.contentType}`)
                return null
            }
          })}
        </div>
      </Container>
    </section>
  )
}

export default FeaturedContent
