import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "@components/elements/container"
import Cta, { CtaProps } from "@components/elements/cta"

import styles from "./careersHero.module.scss"

const MAX_GALLERY_PICTURES = 3

interface CareersHeroProps {
  title: string
}

const CareersHero = ({ title, images, cta }: CareersHeroProps) => (
  <section className={styles.careersHero}>
    <Container className={styles.container} isNarrow>
      {title && <h1 className={styles.title}>{title}</h1>}

      <Cta {...cta} variant="light" className={styles.buttonCta} />
      <div className={styles.gallery}>
        {images?.slice(0, MAX_GALLERY_PICTURES).map((item, index) => {
          const image = getImage(item.imageData)

          return (
            image && (
              <div className={styles.galleryItem} key={index}>
                <GatsbyImage
                  className={styles.galleryPicture}
                  image={image}
                  alt=""
                />
              </div>
            )
          )
        })}
      </div>
    </Container>
  </section>
)

export default CareersHero
